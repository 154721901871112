@charset "UTF-8";
.adminimal-theme.node-type-case .pane-entity-view .links a::before, .page-user .action-links a::before {
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: "Icomoon";
  line-height: 1;
  transform: translate(0, 0);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.adminimal-theme.node-type-case .pane-entity-view .links a, .page-user .action-links a, .adminimal-theme .profile-student-row-3 .user-all-cases a {
  background-color: #0065ad;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

.adminimal-theme.node-type-case .pane-entity-view .links a:hover, .page-user .action-links a:hover, .adminimal-theme .profile-student-row-3 .user-all-cases a:hover {
  background-color: #0074BD;
}

.adminimal-theme .field-name-field-channel-rinses .sticky-header, .adminimal-theme .field-name-field-medical-inlay .sticky-header, .adminimal-theme .field-name-field-additional-x-rays .sticky-header,
.adminimal-theme .field-name-field-x-rays .sticky-header, .adminimal-theme .field-name-field-channel-rinses .tabledrag-toggle-weight-wrapper, .adminimal-theme .field-name-field-medical-inlay .tabledrag-toggle-weight-wrapper, .adminimal-theme .field-name-field-additional-x-rays .tabledrag-toggle-weight-wrapper,
.adminimal-theme .field-name-field-x-rays .tabledrag-toggle-weight-wrapper {
  display: none;
}

.adminimal-theme .field-name-field-channel-rinses td, .adminimal-theme .field-name-field-medical-inlay td, .adminimal-theme .field-name-field-additional-x-rays td,
.adminimal-theme .field-name-field-x-rays td {
  vertical-align: top;
}

.adminimal-theme .field-name-field-channel-rinses .tabledrag, .adminimal-theme .field-name-field-medical-inlay .tabledrag, .adminimal-theme .field-name-field-additional-x-rays .tabledrag,
.adminimal-theme .field-name-field-x-rays .tabledrag, .adminimal-theme .field-name-field-channel-rinses .field-multiple-drag, .adminimal-theme .field-name-field-medical-inlay .field-multiple-drag, .adminimal-theme .field-name-field-additional-x-rays .field-multiple-drag,
.adminimal-theme .field-name-field-x-rays .field-multiple-drag {
  padding: 0;
  width: 0;
}

.adminimal-theme .field-name-field-channel-rinses .tabledrag a, .adminimal-theme .field-name-field-medical-inlay .tabledrag a, .adminimal-theme .field-name-field-additional-x-rays .tabledrag a,
.adminimal-theme .field-name-field-x-rays .tabledrag a, .adminimal-theme .field-name-field-channel-rinses .field-multiple-drag a, .adminimal-theme .field-name-field-medical-inlay .field-multiple-drag a, .adminimal-theme .field-name-field-additional-x-rays .field-multiple-drag a,
.adminimal-theme .field-name-field-x-rays .field-multiple-drag a {
  display: none;
}

.field-name-field-session-start .date-float {
  position: absolute;
  top: auto;
  left: -9999em;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Icomoon";
  src: url("../../fonts/Icomoon.eot");
  src: url("../../fonts/Icomoon.eot?#iefix") format("embedded-opentype"), url("../../fonts/Icomoon.woff") format("woff"), url("../../fonts/Icomoon.ttf") format("truetype"), url("../../fonts/Icomoon.svgIcomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.adminimal-theme .action-links {
  margin: 0;
}

body.adminimal-theme {
  background-color: #333;
  padding-bottom: 40px;
  font-size: 87.5%;
}

.red {
  color: #ff1600;
}

.green {
  color: #00cc19;
}

.adminimal-theme .form-textarea[disabled],
.adminimal-theme .form-select[disabled],
.adminimal-theme .form-text[disabled] {
  background-color: #f3f3f3;
  cursor: not-allowed;
  color: #999;
}

.intensity-color[data-intensity="0"] {
  background-color: #00cc19;
}

.intensity-color[data-intensity="1"] {
  background-color: #fff000;
}

.intensity-color[data-intensity="2"] {
  background-color: #ff8700;
}

.intensity-color[data-intensity="3"] {
  background-color: #ff1600;
}

.adminimal-theme .field-multiple-drag {
  vertical-align: center;
}

.form-item.form-item-field-duration-und {
  margin-bottom: 34px;
}

.form-item.form-item-field-duration-und .description {
  position: absolute;
  left: 0;
}

.adminimal-theme .group-x-ray.form-wrapper {
  margin-top: 0;
}

.adminimal-theme h2 {
  padding-top: 40px;
  margin-bottom: 20px;
  font-size: 1.71429rem;
}

.adminimal-theme h3 {
  font-size: 1.28571rem;
}

[id="logo-header"] {
  display: none;
}

.panel-separator {
  clear: both;
  overflow: auto;
  margin: 2.85714rem 0 0;
}

select.form-select[multiple] {
  background-image: none;
  padding: 0;
}

select.form-select[multiple] option {
  padding: 0.21429rem 0.57143rem;
}

.horizontal-tab-button .form-required + .form-required {
  display: none;
}

.adminimal-theme a.tabledrag-handle {
  display: flex;
  margin: 0;
  padding: 0 0.35714rem;
  height: 2.14286rem;
  align-items: center;
}

.adminimal-theme a.tabledrag-handle .handle {
  margin: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  background-position: top left;
}

.field-name-field-situation-at-transfe-help thead {
  display: none;
}

.view-empty {
  margin-top: 10px;
  color: #999;
}

.case-assignement-list li {
  display: inline-block;
}

.case-assignement-list li::after {
  content: ',';
  margin-right: .25em;
}

.case-assignement-list li:last-child::after {
  display: none;
}

.adminimal-theme ul.case-short-teaser-list a {
  display: inline-block;
}

.adminimal-theme ul.case-short-teaser-list .views-field-changed {
  font-size: 12px;
}

.adminimal-theme ul.case-short-teaser-list em {
  font-style: normal;
}

.adminimal-theme .case-teaser {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.adminimal-theme .case-teaser-header {
  margin: -10px -10px 10px;
  padding: 10px;
  border-bottom: 1px solid #dedede;
  background-color: #f3f3f3;
}

.adminimal-theme .case-teaser-header h3 {
  margin-bottom: 0;
}

.adminimal-theme .case-teaser-header > :first-child {
  margin-top: 0;
}

.adminimal-theme .case-teaser-header > :last-child {
  margin-bottom: 0;
}

.adminimal-theme .case-teaser-main {
  flex-grow: 1;
}

.adminimal-theme .case-teaser-main .field {
  display: flex;
}

.adminimal-theme .case-teaser-main .label-inline {
  flex-basis: 119px;
}

.adminimal-theme .case-teaser-footer {
  margin-top: 20px;
}

.adminimal-theme ul.case-teaser-list {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.adminimal-theme ul.case-teaser-list > li {
  position: relative;
  z-index: 1;
  display: flex;
  margin-left: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  flex-basis: calc(33% - (20px * 2 / 3));
}

.adminimal-theme ul.case-teaser-list > li::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: block;
  content: '';
  transition: box-shadow .3s ease-out;
}

.adminimal-theme ul.case-teaser-list > li:hover::after {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.37);
  transition-timing-function: ease-in;
}

.adminimal-theme ul.case-teaser-list > li:nth-child(3n+1) {
  margin-left: 0;
}

.adminimal-theme ul.case-teaser-list > li:nth-child(n+4) {
  margin-top: 20px;
}

.adminimal-theme .form-checkboxes,
.adminimal-theme .form-radios {
  display: flex;
  padding: 0 0.71429rem 1px;
  border: 0.07143rem solid rgba(0, 0, 0, 0.05);
  border-radius: 0.21429rem;
  flex-wrap: wrap;
}

.adminimal-theme .form-checkboxes > div.form-item,
.adminimal-theme .form-radios > div.form-item {
  margin-top: 0;
  margin-right: 1.42857rem;
}

.adminimal-theme .form-checkboxes > div.form-item:last-child,
.adminimal-theme .form-radios > div.form-item:last-child {
  margin-right: 0;
}

.adminimal-theme .form-checkboxes [type="radio"],
.adminimal-theme .form-checkboxes [type="checkbox"],
.adminimal-theme .form-radios [type="radio"],
.adminimal-theme .form-radios [type="checkbox"] {
  margin-right: 0.14286rem;
  transform: translateY(-1px);
}

.form-type-checkboxes .form-item .form-required {
  display: none;
}

.form-item .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 0;
}

.date-no-float .form-type-textfield label {
  display: none;
}

.field-widget-date-popup .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.field-widget-date-popup .date-float {
  width: 100%;
}

.field-widget-date-popup .date-combo .container-inline-date {
  width: auto;
}

.field-widget-date-popup .date-combo .container-inline-date > .form-item {
  margin-bottom: 0;
}

.field-widget-date-popup .date-combo .container-inline-date label {
  float: left;
  padding-top: 0.85714rem;
}

.field-group-htabs {
  margin-top: 20px;
}

.field-group-htabs:first-child {
  margin-top: 0;
}

.field-group-htabs .horizontal-tabs-panes {
  padding-bottom: 20px;
}

.field-group-htabs .horizontal-tabs-panes > fieldset {
  margin-top: 0;
}

.field-group-div.required-fields h3::after {
  content: ' *';
  color: #f00;
}

.field-name-field-session-start fieldset.date-combo {
  padding: 0;
  border-width: 0;
}

.field-name-field-session-start fieldset.date-combo legend {
  padding: 0 0 3px 3px;
}

.field-name-field-session-start fieldset.date-combo .form-item {
  margin-bottom: 0;
  padding-bottom: 0;
}

.field-name-field-session-start fieldset.date-combo .container-inline-date .date-padding {
  padding: 0;
}

.field-name-field-session-start fieldset.date-combo .container-inline-date label {
  margin-right: 7px;
  padding-top: 6px;
}

.field .label-inline {
  float: left;
  font-weight: bold;
  margin-right: .5em;
}

.flipped-table .views-table tr > :first-child {
  border-right: 0.07143rem solid #dedede;
}

.flipped-table .views-table thead {
  border-bottom: 0.07143rem solid #dedede;
}

.flipped-table .views-table thead th:nth-child(n+2) {
  background-color: #f3f3f3;
}

.flipped-table .views-table tbody tr:hover th {
  background-color: transparent;
}

.form-item select,
.form-item input {
  font-size: 0.92857rem;
  padding-top: 7px;
  padding-bottom: 7px;
}

.adminimal-theme .form-wrapper {
  margin-bottom: 0;
  margin-top: 20px;
}

.adminimal-theme .form-wrapper.required-fields .form-type-radios .form-item .form-required {
  display: none;
}

.adminimal-theme .form-wrapper:first-child {
  margin-top: 0;
}

.adminimal-theme .form-wrapper .form-item {
  margin-bottom: 0;
  margin-top: 20px;
}

.adminimal-theme .form-wrapper .form-item:first-child {
  margin-top: 0;
}

.group-combine-fields {
  position: relative;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.group-combine-fields h3 {
  margin-bottom: 8px;
  width: 100%;
}

.group-combine-fields label {
  display: none;
}

.group-combine-fields .form-wrapper {
  margin-top: 0;
  margin-bottom: 6px;
}

.group-combine-fields .dependee-hidden {
  opacity: .5;
}

.group-duration {
  padding-bottom: 15px;
}

.group-general {
  margin: 40px 0;
}

.group-vertical {
  display: flex;
  margin-top: 20px;
}

.group-vertical [type="text"] {
  width: 100%;
}

.group-vertical .form-item {
  margin-bottom: 0;
}

.group-vertical .form-wrapper {
  margin-top: 0;
}

.adminimal-theme .list-reset {
  padding: 0;
  margin: 0;
}

.adminimal-theme .list-reset > li {
  padding: 0;
  list-style: none;
}

.overflow-fix-processed {
  overflow: scroll;
  min-width: 100%;
}

.overflow-fix-processed .sticky-header {
  display: none;
}

.overflow-info {
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.overflow-info::before {
  margin-right: 10px;
  content: '\2039';
}

.overflow-info::after {
  margin-left: 10px;
  content: '\203A';
}

#field-x-rays-add-more-wrapper {
  min-width: 100%;
}

body.adminimal-theme table th {
  background-color: transparent;
  vertical-align: top;
  font-size: 1rem;
  transition: background-color 0.2s;
}

body.adminimal-theme table th label {
  font-size: inherit;
}

body.adminimal-theme table thead th {
  font-size: 12px;
}

body.adminimal-theme table thead tr:last-child {
  border-bottom: 1px solid #dedede;
}

body.adminimal-theme table tbody tr {
  transition: background-color 0.2s;
}

body.adminimal-theme table tbody tr.even {
  background-color: #f3f3f3;
}

body.adminimal-theme table tbody tr.even:hover, body.adminimal-theme table tbody tr.odd:hover {
  background-color: rgba(0, 101, 173, 0.15);
}

.adminimal-theme .field-name-field-additional-x-rays .date-combo,
.adminimal-theme .field-name-field-x-rays .date-combo {
  margin: 0;
  padding: 0;
  border-width: 0;
}

.adminimal-theme .field-name-field-additional-x-rays .date-combo legend,
.adminimal-theme .field-name-field-x-rays .date-combo legend {
  display: none;
}

.adminimal-theme .field-name-field-additional-x-rays .date-combo .date-padding,
.adminimal-theme .field-name-field-x-rays .date-combo .date-padding {
  padding: 0;
}

.adminimal-theme .field-name-field-additional-x-rays .date-combo .description,
.adminimal-theme .field-name-field-x-rays .date-combo .description {
  margin-bottom: 0;
  font-size: 12px;
}

.adminimal-theme .field-name-field-additional-x-rays .date-combo .form-item input,
.adminimal-theme .field-name-field-x-rays .date-combo .form-item input {
  margin-right: 0;
  padding-right: 8px;
  width: 105px;
}

.adminimal-theme .field-name-field-additional-x-rays .field-threatened-doctor input,
.adminimal-theme .field-name-field-x-rays .field-threatened-doctor input {
  width: 180px;
}

.adminimal-theme .field-name-field-additional-x-rays .form-submit,
.adminimal-theme .field-name-field-x-rays .form-submit {
  margin-right: 0;
}

.adminimal-theme .field-name-field-additional-x-rays .remove-button,
.adminimal-theme .field-name-field-x-rays .remove-button {
  padding-left: 0;
}

.adminimal-theme .teaser-list .view-filters {
  padding: 5px 0;
  background-color: transparent;
}

.adminimal-theme .teaser-list .views-exposed-widgets {
  margin: 0;
}

.views-exposed-widgets {
  display: flex;
  align-items: flex-start;
}

.views-exposed-widgets .views-exposed-widget {
  padding-top: 0;
}

.views-exposed-widgets .views-exposed-widget label {
  font-weight: normal;
  font-size: 0.85714rem;
  padding: 0;
}

.views-exposed-widgets .views-exposed-widget input {
  max-width: 100%;
  width: 100%;
}

.views-exposed-form .views-exposed-widgets .views-exposed-widget .form-submit {
  margin: 1.28571rem 0 0;
}

.page-admin-structure.adminimal-theme #branding,
.page-admin-structure.adminimal-theme #navigation {
  max-width: none;
}

.page-admin-structure.adminimal-theme #page {
  max-width: 100%;
  overflow-x: scroll;
}

.adminimal-theme.node-type-case .field-name-field-purpose-of-x-ray {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
}

.adminimal-theme.node-type-case .field-name-field-x-ray {
  margin-top: 10px;
}

.adminimal-theme.node-type-case .field-name-field-x-rays {
  display: flex;
  justify-content: space-between;
}

.adminimal-theme.node-type-case .field-name-field-x-rays img {
  max-width: 100%;
  height: auto;
}

.adminimal-theme.node-type-case .field-name-field-x-rays > div {
  flex-basis: 31%;
}

.adminimal-theme.node-type-case .pane-entity-view {
  margin-bottom: 40px;
}

.adminimal-theme.node-type-case .pane-entity-view .links {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.adminimal-theme.node-type-case .pane-entity-view .links .comment-add {
  display: none;
}

.adminimal-theme.node-type-case .pane-entity-view .links a {
  font-weight: bold;
}

.adminimal-theme.node-type-case .pane-entity-view .links a::before {
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.adminimal-theme.node-type-case .pane-entity-view .links .flag-reject_case a {
  background-color: #ff1600;
}

.adminimal-theme.node-type-case .pane-entity-view .links .flag-reject_case a::before {
  content: "";
}

.adminimal-theme.node-type-case .pane-entity-view .links .flag-accept_case a {
  background-color: #00cc19;
}

.adminimal-theme.node-type-case .pane-entity-view .links .flag-accept_case a::before {
  content: "";
}

.adminimal-theme.node-type-case .flag-message {
  position: static;
}

.adminimal-theme.node-type-case .pane-node-comment-wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.adminimal-theme.node-type-case .pane-node-comment-wrapper [id^="edit-author"] {
  display: none;
}

.adminimal-theme.node-type-case .pane-node-comment-wrapper .text-format-wrapper .form-type-textarea > label {
  display: none;
}

.adminimal-theme.page-node-edit #page, .adminimal-theme.page-node-complete #page, .adminimal-theme.page-node-add-case #page {
  overflow: hidden;
}

.page-user .view-display-id-count_cases thead tr > :nth-child(n+2),
.page-user .view-display-id-count_cases tbody tr > :nth-child(n+2) {
  text-align: right;
}

.page-user .view-display-id-count_cases .total {
  border-bottom: 0.07143rem solid #dedede;
}

.page-user .action-links a {
  position: absolute;
  right: 10px;
  padding: 0;
  bottom: 100%;
  z-index: 100;
  display: block;
  width: 72px;
  height: 72px;
  border-width: 0;
  border-radius: 50%;
  text-indent: -9999em;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
}

.page-user .action-links a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  text-indent: 0;
  font-size: 48px;
  transform: translate(-50%, -50%);
}

.page-user .views-exposed-form .form-item {
  padding-right: 0;
}

.page-user .form-item-uid input {
  width: 290px;
}

.page-user .form-item-title-field-value input {
  width: 200px;
}

.adminimal-theme .profile-student-row-2, .adminimal-theme .profile-student-row-3 {
  display: flex;
}

.adminimal-theme .profile-student-row-2 > div, .adminimal-theme .profile-student-row-3 > div {
  margin-left: 40px;
}

.adminimal-theme .profile-student-row-2 > :first-child, .adminimal-theme .profile-student-row-3 > :first-child {
  margin-left: 0;
}

.adminimal-theme .profile-student-row-2 > div {
  flex-basis: 20%;
}

.adminimal-theme .profile-student-row-2 > :first-child {
  flex-grow: 1;
}

.adminimal-theme .profile-student-row-3 {
  flex-wrap: wrap;
}

.adminimal-theme .profile-student-row-3 h2 {
  padding-top: 0;
}

.adminimal-theme .profile-student-row-3 > div {
  margin-left: 40px;
  flex-basis: calc(33% - 26.66667px);
  flex-shrink: 1;
}

.adminimal-theme .profile-student-row-3 > div:nth-child(n+2) {
  padding-top: 30px;
}

.adminimal-theme .profile-student-row-3 .view-footer {
  margin-top: 20px;
}

.adminimal-theme .profile-student-row-3 .user-all-cases {
  margin-left: 0;
  padding-top: 20px;
  text-align: center;
  flex-basis: 100%;
  flex-shrink: 0;
}

.adminimal-theme .profile-student-row-3 .user-all-cases .pane-content {
  position: relative;
  z-index: 1;
}

.adminimal-theme .profile-student-row-3 .user-all-cases .pane-content::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: -1;
  display: block;
  height: 1px;
  background-color: #dedede;
  content: '';
}

.adminimal-theme .profile-student-row-3 .user-all-cases a {
  position: relative;
}

.adminimal-theme .profile-student-row-3 .user-all-cases a::after {
  position: absolute;
  right: -10px;
  left: -10px;
  z-index: -1;
  background: #fff;
  content: '';
  height: 100%;
  pointer-events: none;
}

.adminimal-theme .profile-student-row-3 .user-all-cases + div {
  margin-left: 0;
}

.adminimal-theme .profile-student-row-3 .views-field-field-patient-nr,
.adminimal-theme .profile-student-row-3 .views-field-field-shortcode {
  display: inline-flex;
}

.adminimal-theme .profile-student-row-3 .views-field-field-patient-nr::before {
  content: '#';
}

.adminimal-theme .profile-student-row-3 .views-field-field-patient-nr + .views-field-field-shortcode::before {
  margin: 0 7px 0 4px;
  content: '\2022';
}

.adminimal-theme #branding,
.adminimal-theme #navigation,
.adminimal-theme #page {
  margin: 0 auto;
  max-width: 1200px;
}

.adminimal-theme #branding {
  background-color: transparent;
}

.adminimal-theme #page {
  padding: 20px;
}
