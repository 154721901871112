.adminimal-theme .case-teaser {
  // item
  $inner-spacing: $spacing-minor;

  display: flex;
  flex-direction: column;
  padding: $inner-spacing;
  width: 100%;

  &-header {
    margin: (0 - $inner-spacing) (0 - $inner-spacing) $spacing-minor;
    padding: $inner-spacing;
    border-bottom: 1px solid $color-border-default;
    background-color: $color-table-row-even;

    h3 {
      margin-bottom: 0;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &-main {
    $label-width: 109px;

    flex-grow: 1;

    .field {
      display: flex;
    }

    .label-inline {
      flex-basis: $label-width + $spacing-minor;
    }
  }

  &-footer {
    margin-top: $spacing-default;
  }
}

// list
.adminimal-theme ul.case-teaser-list {
  display: flex;
  margin-top: $spacing-default;
  flex-wrap: wrap;

  > li {
    position: relative;
    z-index: 1;
    display: flex;
    margin-left: $spacing-default;
    box-shadow: 0 1px 3px 0 rgba($color-black, .22);
    flex-basis: calc(33% - (#{$spacing-default} * 2 / 3));

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      content: '';
      transition: box-shadow .3s ease-out;
    }

    &:hover {
      &::after {
        box-shadow: 0 3px 15px 0 rgba($color-black, .37);
        transition-timing-function: ease-in;
      }
    }

    &:nth-child(3n+1) {
      margin-left: 0;
    }

    &:nth-child(n+4) {
      margin-top: $spacing-default;
    }
  }
}

// Cases per category