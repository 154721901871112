$icon-font-family: 'Icomoon';

$icon-font-trivials: (
  // google material
  // https://design.google.com/icons/
  error: '\e000',
  error-outline: '\e001',
  warning: '\e002',
  add-alert: '\e003',
  album: '\e019',
  av-timer: '\e01b',
  closed-caption: '\e01c',
  equalizer: '\e01d',
  explicit: '\e01e',
  fast-forward: '\e01f',
  fast-rewind: '\e020',
  games: '\e021',
  hearing: '\e023',
  high-quality: '\e024',
  loop: '\e028',
  mic: '\e029',
  mic-none: '\e02a',
  mic-off: '\e02b',
  movie: '\e02c',
  library-add: '\e02e',
  library-books: '\e02f',
  library-music: '\e030',
  new-releases: '\e031',
  not-interested: '\e033',
  pause: '\e034',
  pause-circle-filled: '\e035',
  pause-circle-outline: '\e036',
  play-arrow: '\e037',
  play-circle-filled: '\e038',
  play-circle-outline: '\e039',
  playlist-add: '\e03b',
  queue: '\e03c',
  queue-music: '\e03d',
  radio: '\e03e',
  recent-actors: '\e03f',
  repeat: '\e040',
  repeat-one: '\e041',
  replay: '\e042',
  shuffle: '\e043',
  skip-next: '\e044',
  skip-previous: '\e045',
  snooze: '\e046',
  stop: '\e047',
  subtitles: '\e048',
  surround-sound: '\e049',
  video-collection: '\e04a',
  videocam: '\e04b',
  videocam-off: '\e04c',
  volume-down: '\e04d',
  volume-mute: '\e04e',
  volume-off: '\e04f',
  volume-up: '\e050',
  web: '\e051',
  hd: '\e052',
  sort-by-alpha: '\e053',
  airplay: '\e055',
  forward-10: '\e056',
  forward-30: '\e057',
  forward-5: '\e058',
  replay-10: '\e059',
  replay-30: '\e05a',
  replay-5: '\e05b',
  add-to-queue: '\e05c',
  fiber-dvr: '\e05d',
  fiber-new: '\e05e',
  playlist-play: '\e05f',
  art-track: '\e060',
  fiber-manual-record: '\e061',
  fiber-smart-record: '\e062',
  music-video: '\e063',
  subscriptions: '\e064',
  playlist-add-check: '\e065',
  queue-play-next: '\e066',
  remove-from-queue: '\e067',
  slow-motion-video: '\e068',
  web-asset: '\e069',
  fiber-pin: '\e06a',
  business: '\e0af',
  call: '\e0b0',
  call-end: '\e0b1',
  call-made: '\e0b2',
  call-merge: '\e0b3',
  call-missed: '\e0b4',
  call-received: '\e0b5',
  call-split: '\e0b6',
  chat: '\e0b7',
  clear-all: '\e0b8',
  comment: '\e0b9',
  contacts: '\e0ba',
  dialer-sip: '\e0bb',
  dialpad: '\e0bc',
  email: '\e0be',
  forum: '\e0bf',
  import-export: '\e0c3',
  invert-colors-off: '\e0c4',
  live-help: '\e0c6',
  location-off: '\e0c7',
  location-on: '\e0c8',
  message: '\e0c9',
  chat-bubble: '\e0ca',
  chat-bubble-outline: '\e0cb',
  no-sim: '\e0cc',
  phone: '\e0cd',
  portable-wifi-off: '\e0ce',
  contact-phone: '\e0cf',
  contact-mail: '\e0d0',
  ring-volume: '\e0d1',
  speaker-phone: '\e0d2',
  stay-current-landscape: '\e0d3',
  stay-current-portrait: '\e0d4',
  stay-primary-landscape: '\e0d5',
  stay-primary-portrait: '\e0d6',
  swap-calls: '\e0d7',
  textsms: '\e0d8',
  voicemail: '\e0d9',
  vpn-key: '\e0da',
  phonelink-erase: '\e0db',
  phonelink-lock: '\e0dc',
  phonelink-ring: '\e0dd',
  phonelink-setup: '\e0de',
  present-to-all: '\e0df',
  import-contacts: '\e0e0',
  mail-outline: '\e0e1',
  screen-share: '\e0e2',
  stop-screen-share: '\e0e3',
  call-missed-outgoing: '\e0e4',
  add: '\e145',
  add-box: '\e146',
  add-circle: '\e147',
  add-circle-outline: '\e148',
  archive: '\e149',
  backspace: '\e14a',
  block: '\e14b',
  clear: '\e14c',
  content-copy: '\e14d',
  content-cut: '\e14e',
  content-paste: '\e14f',
  create: '\e150',
  drafts: '\e151',
  filter-list: '\e152',
  flag: '\e153',
  forward: '\e154',
  gesture: '\e155',
  inbox: '\e156',
  link: '\e157',
  mail: '\e158',
  markunread: '\e159',
  redo: '\e15a',
  remove: '\e15b',
  remove-circle: '\e15c',
  remove-circle-outline: '\e15d',
  reply: '\e15e',
  reply-all: '\e15f',
  report: '\e160',
  save: '\e161',
  select-all: '\e162',
  send: '\e163',
  sort: '\e164',
  text-format: '\e165',
  undo: '\e166',
  font-download: '\e167',
  move-to-inbox: '\e168',
  unarchive: '\e169',
  next-week: '\e16a',
  weekend: '\e16b',
  access-alarm: '\e190',
  access-alarms: '\e191',
  access-time: '\e192',
  add-alarm: '\e193',
  airplanemode-inactive: '\e194',
  airplanemode-active: '\e195',
  battery-alert: '\e19c',
  battery-charging-full: '\e1a3',
  battery-full: '\e1a4',
  battery-std: '\e1a5',
  battery-unknown: '\e1a6',
  bluetooth: '\e1a7',
  bluetooth-connected: '\e1a8',
  bluetooth-disabled: '\e1a9',
  bluetooth-searching: '\e1aa',
  brightness-auto: '\e1ab',
  brightness-high: '\e1ac',
  brightness-low: '\e1ad',
  brightness-medium: '\e1ae',
  data-usage: '\e1af',
  developer-mode: '\e1b0',
  devices: '\e1b1',
  dvr: '\e1b2',
  gps-fixed: '\e1b3',
  gps-not-fixed: '\e1b4',
  gps-off: '\e1b5',
  location-disabled: '\e1b6',
  location-searching: '\e1b7',
  graphic-eq: '\e1b8',
  network-cell: '\e1b9',
  network-wifi: '\e1ba',
  nfc: '\e1bb',
  now-wallpaper: '\e1bc',
  now-widgets: '\e1bd',
  screen-lock-landscape: '\e1be',
  screen-lock-portrait: '\e1bf',
  screen-lock-rotation: '\e1c0',
  screen-rotation: '\e1c1',
  sd-storage: '\e1c2',
  settings-system-daydream: '\e1c3',
  signal-cellular-4-bar: '\e1c8',
  signal-cellular-connected-no-internet-4-bar: '\e1cd',
  signal-cellular-no-sim: '\e1ce',
  signal-cellular-null: '\e1cf',
  signal-cellular-off: '\e1d0',
  signal-wifi-4-bar: '\e1d8',
  signal-wifi-4-bar-lock: '\e1d9',
  signal-wifi-off: '\e1da',
  storage: '\e1db',
  usb: '\e1e0',
  wifi-lock: '\e1e1',
  wifi-tethering: '\e1e2',
  attach-file: '\e226',
  attach-money: '\e227',
  border-all: '\e228',
  border-bottom: '\e229',
  border-clear: '\e22a',
  border-color: '\e22b',
  border-horizontal: '\e22c',
  border-inner: '\e22d',
  border-left: '\e22e',
  border-outer: '\e22f',
  border-right: '\e230',
  border-style: '\e231',
  border-top: '\e232',
  border-vertical: '\e233',
  format-align-center: '\e234',
  format-align-justify: '\e235',
  format-align-left: '\e236',
  format-align-right: '\e237',
  format-bold: '\e238',
  format-clear: '\e239',
  format-color-fill: '\e23a',
  format-color-reset: '\e23b',
  format-color-text: '\e23c',
  format-indent-decrease: '\e23d',
  format-indent-increase: '\e23e',
  format-italic: '\e23f',
  format-line-spacing: '\e240',
  format-list-bulleted: '\e241',
  format-list-numbered: '\e242',
  format-paint: '\e243',
  format-quote: '\e244',
  format-size: '\e245',
  format-strikethrough: '\e246',
  format-textdirection-l-to-r: '\e247',
  format-textdirection-r-to-l: '\e248',
  format-underlined: '\e249',
  functions: '\e24a',
  insert-chart: '\e24b',
  insert-comment: '\e24c',
  insert-drive-file: '\e24d',
  insert-emoticon: '\e24e',
  insert-invitation: '\e24f',
  insert-link: '\e250',
  insert-photo: '\e251',
  merge-type: '\e252',
  mode-comment: '\e253',
  mode-edit: '\e254',
  publish: '\e255',
  space-bar: '\e256',
  strikethrough-s: '\e257',
  vertical-align-bottom: '\e258',
  vertical-align-center: '\e259',
  vertical-align-top: '\e25a',
  wrap-text: '\e25b',
  money-off: '\e25c',
  drag-handle: '\e25d',
  format-shapes: '\e25e',
  highlight: '\e25f',
  linear-scale: '\e260',
  short-text: '\e261',
  text-fields: '\e262',
  attachment: '\e2bc',
  cloud: '\e2bd',
  cloud-circle: '\e2be',
  cloud-done: '\e2bf',
  cloud-download: '\e2c0',
  cloud-off: '\e2c1',
  cloud-queue: '\e2c2',
  cloud-upload: '\e2c3',
  file-download: '\e2c4',
  file-upload: '\e2c6',
  folder: '\e2c7',
  folder-open: '\e2c8',
  folder-shared: '\e2c9',
  create-new-folder: '\e2cc',
  cast: '\e307',
  cast-connected: '\e308',
  computer: '\e30a',
  desktop-mac: '\e30b',
  desktop-windows: '\e30c',
  developer-board: '\e30d',
  dock: '\e30e',
  gamepad: '\e30f',
  headset: '\e310',
  headset-mic: '\e311',
  keyboard: '\e312',
  keyboard-arrow-down: '\e313',
  keyboard-arrow-left: '\e314',
  keyboard-arrow-right: '\e315',
  keyboard-arrow-up: '\e316',
  keyboard-backspace: '\e317',
  keyboard-capslock: '\e318',
  keyboard-hide: '\e31a',
  keyboard-return: '\e31b',
  keyboard-tab: '\e31c',
  keyboard-voice: '\e31d',
  laptop: '\e31e',
  laptop-chromebook: '\e31f',
  laptop-mac: '\e320',
  laptop-windows: '\e321',
  memory: '\e322',
  mouse: '\e323',
  phone-android: '\e324',
  phone-iphone: '\e325',
  phonelink: '\e326',
  phonelink-off: '\e327',
  router: '\e328',
  scanner: '\e329',
  security: '\e32a',
  sim-card: '\e32b',
  smartphone: '\e32c',
  speaker: '\e32d',
  speaker-group: '\e32e',
  tablet: '\e32f',
  tablet-android: '\e330',
  tablet-mac: '\e331',
  toys: '\e332',
  tv: '\e333',
  watch: '\e334',
  device-hub: '\e335',
  power-input: '\e336',
  devices-other: '\e337',
  videogame-asset: '\e338',
  add-to-photos: '\e39d',
  adjust: '\e39e',
  assistant: '\e39f',
  assistant-photo: '\e3a0',
  audiotrack: '\e3a1',
  blur-circular: '\e3a2',
  blur-linear: '\e3a3',
  blur-off: '\e3a4',
  blur-on: '\e3a5',
  brightness-1: '\e3a6',
  brightness-2: '\e3a7',
  brightness-3: '\e3a8',
  brightness-4: '\e3a9',
  brightness-5: '\e3aa',
  brightness-6: '\e3ab',
  brightness-7: '\e3ac',
  broken-image: '\e3ad',
  brush: '\e3ae',
  camera: '\e3af',
  camera-alt: '\e3b0',
  camera-front: '\e3b1',
  camera-rear: '\e3b2',
  camera-roll: '\e3b3',
  center-focus-strong: '\e3b4',
  center-focus-weak: '\e3b5',
  collections: '\e3b6',
  color-lens: '\e3b7',
  colorize: '\e3b8',
  compare: '\e3b9',
  control-point: '\e3ba',
  control-point-duplicate: '\e3bb',
  crop-16-9: '\e3bc',
  crop-3-2: '\e3bd',
  crop: '\e3be',
  crop-5-4: '\e3bf',
  crop-7-5: '\e3c0',
  crop-din: '\e3c1',
  crop-free: '\e3c2',
  crop-landscape: '\e3c3',
  crop-original: '\e3c4',
  crop-portrait: '\e3c5',
  crop-square: '\e3c6',
  dehaze: '\e3c7',
  details: '\e3c8',
  edit: '\e3c9',
  exposure: '\e3ca',
  exposure-minus-1: '\e3cb',
  exposure-minus-2: '\e3cc',
  exposure-plus-1: '\e3cd',
  exposure-plus-2: '\e3ce',
  exposure-zero: '\e3cf',
  filter-1: '\e3d0',
  filter-2: '\e3d1',
  filter-3: '\e3d2',
  filter: '\e3d3',
  filter-4: '\e3d4',
  filter-5: '\e3d5',
  filter-6: '\e3d6',
  filter-7: '\e3d7',
  filter-8: '\e3d8',
  filter-9: '\e3d9',
  filter-9-plus: '\e3da',
  filter-b-and-w: '\e3db',
  filter-center-focus: '\e3dc',
  filter-drama: '\e3dd',
  filter-frames: '\e3de',
  filter-hdr: '\e3df',
  filter-none: '\e3e0',
  filter-tilt-shift: '\e3e2',
  filter-vintage: '\e3e3',
  flare: '\e3e4',
  flash-auto: '\e3e5',
  flash-off: '\e3e6',
  flash-on: '\e3e7',
  flip: '\e3e8',
  gradient: '\e3e9',
  grain: '\e3ea',
  grid-off: '\e3eb',
  grid-on: '\e3ec',
  hdr-off: '\e3ed',
  hdr-on: '\e3ee',
  hdr-strong: '\e3f1',
  hdr-weak: '\e3f2',
  healing: '\e3f3',
  image: '\e3f4',
  image-aspect-ratio: '\e3f5',
  iso: '\e3f6',
  landscape: '\e3f7',
  leak-add: '\e3f8',
  leak-remove: '\e3f9',
  lens: '\e3fa',
  looks-3: '\e3fb',
  looks: '\e3fc',
  looks-4: '\e3fd',
  looks-5: '\e3fe',
  looks-6: '\e3ff',
  looks-one: '\e400',
  looks-two: '\e401',
  loupe: '\e402',
  monochrome-photos: '\e403',
  movie-creation: '\e404',
  music-note: '\e405',
  nature: '\e406',
  nature-people: '\e407',
  navigate-before: '\e408',
  navigate-next: '\e409',
  palette: '\e40a',
  panorama: '\e40b',
  panorama-fish-eye: '\e40c',
  panorama-horizontal: '\e40d',
  panorama-vertical: '\e40e',
  panorama-wide-angle: '\e40f',
  photo: '\e410',
  photo-album: '\e411',
  photo-camera: '\e412',
  photo-library: '\e413',
  picture-as-pdf: '\e415',
  portrait: '\e416',
  remove-red-eye: '\e417',
  rotate-90-degrees-ccw: '\e418',
  rotate-left: '\e419',
  rotate-right: '\e41a',
  slideshow: '\e41b',
  straighten: '\e41c',
  style: '\e41d',
  switch-camera: '\e41e',
  switch-video: '\e41f',
  tag-faces: '\e420',
  texture: '\e421',
  timelapse: '\e422',
  timer-10: '\e423',
  timer-3: '\e424',
  timer: '\e425',
  timer-off: '\e426',
  tonality: '\e427',
  transform: '\e428',
  tune: '\e429',
  view-comfortable: '\e42a',
  view-compact: '\e42b',
  wb-auto: '\e42c',
  wb-cloudy: '\e42d',
  wb-incandescent: '\e42e',
  wb-sunny: '\e430',
  collections-bookmark: '\e431',
  photo-size-select-actual: '\e432',
  photo-size-select-large: '\e433',
  photo-size-select-small: '\e434',
  vignette: '\e435',
  wb-iridescent: '\e436',
  crop-rotate: '\e437',
  linked-camera: '\e438',
  add-a-photo: '\e439',
  movie-filter: '\e43a',
  photo-filter: '\e43b',
  beenhere: '\e52d',
  directions: '\e52e',
  directions-bike: '\e52f',
  directions-bus: '\e530',
  directions-car: '\e531',
  directions-ferry: '\e532',
  directions-subway: '\e533',
  directions-railway: '\e534',
  directions-transit: '\e535',
  directions-walk: '\e536',
  flight: '\e539',
  hotel: '\e53a',
  layers: '\e53b',
  layers-clear: '\e53c',
  local-airport: '\e53d',
  local-atm: '\e53e',
  local-attraction: '\e53f',
  local-bar: '\e540',
  local-cafe: '\e541',
  local-car-wash: '\e542',
  local-convenience-store: '\e543',
  local-drink: '\e544',
  local-florist: '\e545',
  local-gas-station: '\e546',
  local-grocery-store: '\e547',
  local-hospital: '\e548',
  local-hotel: '\e549',
  local-laundry-service: '\e54a',
  local-library: '\e54b',
  local-mall: '\e54c',
  local-movies: '\e54d',
  local-offer: '\e54e',
  local-parking: '\e54f',
  local-pharmacy: '\e550',
  local-phone: '\e551',
  local-pizza: '\e552',
  local-play: '\e553',
  local-post-office: '\e554',
  local-print-shop: '\e555',
  local-restaurant: '\e556',
  local-see: '\e557',
  local-shipping: '\e558',
  local-taxi: '\e559',
  location-history: '\e55a',
  map: '\e55b',
  my-location: '\e55c',
  navigation: '\e55d',
  pin-drop: '\e55e',
  place: '\e55f',
  rate-review: '\e560',
  restaurant-menu: '\e561',
  satellite: '\e562',
  store-mall-directory: '\e563',
  terrain: '\e564',
  traffic: '\e565',
  directions-run: '\e566',
  add-location: '\e567',
  edit-location: '\e568',
  near-me: '\e569',
  person-pin-circle: '\e56a',
  zoom-out-map: '\e56b',
  apps: '\e5c3',
  arrow-back: '\e5c4',
  arrow-drop-down: '\e5c5',
  arrow-drop-down-circle: '\e5c6',
  arrow-drop-up: '\e5c7',
  arrow-forward: '\e5c8',
  cancel: '\e5c9',
  check: '\e5ca',
  chevron-left: '\e5cb',
  chevron-right: '\e5cc',
  close: '\e5cd',
  expand-less: '\e5ce',
  expand-more: '\e5cf',
  fullscreen: '\e5d0',
  fullscreen-exit: '\e5d1',
  menu: '\e5d2',
  keyboard-control: '\e5d3',
  more-vert: '\e5d4',
  refresh: '\e5d5',
  unfold-less: '\e5d6',
  unfold-more: '\e5d7',
  arrow-upward: '\e5d8',
  subdirectory-arrow-left: '\e5d9',
  subdirectory-arrow-right: '\e5da',
  arrow-downward: '\e5db',
  adb: '\e60e',
  bluetooth-audio: '\e60f',
  disc-full: '\e610',
  do-not-disturb-alt: '\e611',
  do-not-disturb: '\e612',
  drive-eta: '\e613',
  event-available: '\e614',
  event-busy: '\e615',
  event-note: '\e616',
  folder-special: '\e617',
  mms: '\e618',
  more: '\e619',
  network-locked: '\e61a',
  phone-bluetooth-speaker: '\e61b',
  phone-forwarded: '\e61c',
  phone-in-talk: '\e61d',
  phone-locked: '\e61e',
  phone-missed: '\e61f',
  phone-paused: '\e620',
  sd-card: '\e623',
  sim-card-alert: '\e624',
  sms: '\e625',
  sms-failed: '\e626',
  sync: '\e627',
  sync-disabled: '\e628',
  sync-problem: '\e629',
  system-update: '\e62a',
  tap-and-play: '\e62b',
  time-to-leave: '\e62c',
  vibration: '\e62d',
  voice-chat: '\e62e',
  vpn-lock: '\e62f',
  airline-seat-flat: '\e630',
  airline-seat-flat-angled: '\e631',
  airline-seat-individual-suite: '\e632',
  airline-seat-legroom-extra: '\e633',
  airline-seat-legroom-normal: '\e634',
  airline-seat-legroom-reduced: '\e635',
  airline-seat-recline-extra: '\e636',
  airline-seat-recline-normal: '\e637',
  confirmation-number: '\e638',
  live-tv: '\e639',
  ondemand-video: '\e63a',
  personal-video: '\e63b',
  power: '\e63c',
  wc: '\e63d',
  wifi: '\e63e',
  enhanced-encryption: '\e63f',
  network-check: '\e640',
  no-encryption: '\e641',
  rv-hookup: '\e642',
  cake: '\e7e9',
  domain: '\e7ee',
  group: '\e7ef',
  group-add: '\e7f0',
  location-city: '\e7f1',
  mood: '\e7f2',
  mood-bad: '\e7f3',
  notifications: '\e7f4',
  notifications-none: '\e7f5',
  notifications-off: '\e7f6',
  notifications-active: '\e7f7',
  notifications-paused: '\e7f8',
  pages: '\e7f9',
  party-mode: '\e7fa',
  people: '\e7fb',
  people-outline: '\e7fc',
  person: '\e7fd',
  person-add: '\e7fe',
  person-outline: '\e7ff',
  plus-one: '\e800',
  poll: '\e801',
  public: '\e80b',
  school: '\e80c',
  share: '\e80d',
  whatshot: '\e80e',
  check-box: '\e834',
  check-box-outline-blank: '\e835',
  radio-button-unchecked: '\e836',
  radio-button-checked: '\e837',
  star: '\e838',
  star-half: '\e839',
  star-outline: '\e83a',
  3 d-rotation: '\e84d',
  accessibility: '\e84e',
  account-balance: '\e84f',
  account-balance-wallet: '\e850',
  account-box: '\e851',
  account-circle: '\e853',
  add-shopping-cart: '\e854',
  alarm: '\e855',
  alarm-add: '\e856',
  alarm-off: '\e857',
  alarm-on: '\e858',
  android: '\e859',
  announcement: '\e85a',
  aspect-ratio: '\e85b',
  assessment: '\e85c',
  assignment: '\e85d',
  assignment-ind: '\e85e',
  assignment-late: '\e85f',
  assignment-return: '\e860',
  assignment-returned: '\e861',
  assignment-turned-in: '\e862',
  autorenew: '\e863',
  backup: '\e864',
  book: '\e865',
  bookmark: '\e866',
  bookmark-outline: '\e867',
  bug-report: '\e868',
  build: '\e869',
  cached: '\e86a',
  change-history: '\e86b',
  check-circle: '\e86c',
  chrome-reader-mode: '\e86d',
  class: '\e86e',
  code: '\e86f',
  credit-card: '\e870',
  dashboard: '\e871',
  delete: '\e872',
  description: '\e873',
  dns: '\e875',
  done: '\e876',
  done-all: '\e877',
  event: '\e878',
  exit-to-app: '\e879',
  explore: '\e87a',
  extension: '\e87b',
  face: '\e87c',
  favorite: '\e87d',
  favorite-outline: '\e87e',
  feedback: '\e87f',
  find-in-page: '\e880',
  find-replace: '\e881',
  flip-to-back: '\e882',
  flip-to-front: '\e883',
  get-app: '\e884',
  grade: '\e885',
  group-work: '\e886',
  help: '\e887',
  highlight-remove: '\e888',
  history: '\e889',
  home: '\e88a',
  hourglass-empty: '\e88b',
  hourglass-full: '\e88c',
  https: '\e88d',
  info: '\e88e',
  info-outline: '\e88f',
  input: '\e890',
  invert-colors-on: '\e891',
  label: '\e892',
  label-outline: '\e893',
  language: '\e894',
  launch: '\e895',
  list: '\e896',
  lock: '\e897',
  lock-open: '\e898',
  lock-outline: '\e899',
  loyalty: '\e89a',
  markunread-mailbox: '\e89b',
  note-add: '\e89c',
  open-in-browser: '\e89d',
  open-in-new: '\e89e',
  open-with: '\e89f',
  pageview: '\e8a0',
  payment: '\e8a1',
  perm-camera-mic: '\e8a2',
  perm-contact-calendar: '\e8a3',
  perm-data-setting: '\e8a4',
  perm-device-information: '\e8a5',
  perm-identity: '\e8a6',
  perm-media: '\e8a7',
  perm-phone-msg: '\e8a8',
  perm-scan-wifi: '\e8a9',
  picture-in-picture: '\e8aa',
  polymer: '\e8ab',
  power-settings-new: '\e8ac',
  print: '\e8ad',
  query-builder: '\e8ae',
  question-answer: '\e8af',
  receipt: '\e8b0',
  redeem: '\e8b1',
  report-problem: '\e8b2',
  restore: '\e8b3',
  room: '\e8b4',
  schedule: '\e8b5',
  search: '\e8b6',
  settings: '\e8b8',
  settings-applications: '\e8b9',
  settings-backup-restore: '\e8ba',
  settings-bluetooth: '\e8bb',
  settings-cell: '\e8bc',
  settings-brightness: '\e8bd',
  settings-ethernet: '\e8be',
  settings-input-antenna: '\e8bf',
  settings-input-component: '\e8c0',
  settings-input-composite: '\e8c1',
  settings-input-hdmi: '\e8c2',
  settings-input-svideo: '\e8c3',
  settings-overscan: '\e8c4',
  settings-phone: '\e8c5',
  settings-power: '\e8c6',
  settings-remote: '\e8c7',
  settings-voice: '\e8c8',
  shop: '\e8c9',
  shop-two: '\e8ca',
  shopping-basket: '\e8cb',
  shopping-cart: '\e8cc',
  speaker-notes: '\e8cd',
  spellcheck: '\e8ce',
  stars: '\e8d0',
  store: '\e8d1',
  subject: '\e8d2',
  supervisor-account: '\e8d3',
  swap-horiz: '\e8d4',
  swap-vert: '\e8d5',
  swap-vertical-circle: '\e8d6',
  system-update-alt: '\e8d7',
  tab: '\e8d8',
  tab-unselected: '\e8d9',
  theaters: '\e8da',
  thumb-down: '\e8db',
  thumb-up: '\e8dc',
  thumbs-up-down: '\e8dd',
  toc: '\e8de',
  today: '\e8df',
  toll: '\e8e0',
  track-changes: '\e8e1',
  translate: '\e8e2',
  trending-down: '\e8e3',
  trending-neutral: '\e8e4',
  trending-up: '\e8e5',
  turned-in: '\e8e6',
  turned-in-not: '\e8e7',
  verified-user: '\e8e8',
  view-agenda: '\e8e9',
  view-array: '\e8ea',
  view-carousel: '\e8eb',
  view-column: '\e8ec',
  view-day: '\e8ed',
  view-headline: '\e8ee',
  view-list: '\e8ef',
  view-module: '\e8f0',
  view-quilt: '\e8f1',
  view-stream: '\e8f2',
  view-week: '\e8f3',
  visibility: '\e8f4',
  visibility-off: '\e8f5',
  card-giftcard: '\e8f6',
  card-membership: '\e8f7',
  card-travel: '\e8f8',
  work: '\e8f9',
  youtube-searched-for: '\e8fa',
  eject: '\e8fb',
  camera-enhance: '\e8fc',
  help-outline: '\e8fd',
  reorder: '\e8fe',
  zoom-in: '\e8ff',
  zoom-out: '\e900',
  http: '\e902',
  event-seat: '\e903',
  flight-land: '\e904',
  flight-takeoff: '\e905',
  play-for-work: '\e906',
  gif: '\e908',
  indeterminate-check-box: '\e909',
  offline-pin: '\e90a',
  all-out: '\e90b',
  copyright: '\e90c',
  fingerprint: '\e90d',
  gavel: '\e90e',
  lightbulb-outline: '\e90f',
  picture-in-picture-alt: '\e911',
  important-devices: '\e912',
  touch-app: '\e913',
  accessible: '\e914',
  compare-arrows: '\e915',
  date-range: '\e916',
  donut-large: '\e917',
  donut-small: '\e918',
  line-style: '\e919',
  line-weight: '\e91a',
  motorcycle: '\e91b',
  opacity: '\e91c',
  pets: '\e91d',
  pregnant-woman: '\e91e',
  record-voice-over: '\e91f',
  rounded-corner: '\e920',
  rowing: '\e921',
  timeline: '\e922',
  update: '\e923',
  watch-later: '\e924',
  pan-tool: '\e925',
  ac-unit: '\eb3b',
  airport-shuttle: '\eb3c',
  all-inclusive: '\eb3d',
  beach-access: '\eb3e',
  business-center: '\eb3f',
  casino: '\eb40',
  child-care: '\eb41',
  child-friendly: '\eb42',
  fitness-center: '\eb43',
  free-breakfast: '\eb44',
  golf-course: '\eb45',
  hot-tub: '\eb46',
  kitchen: '\eb47',
  pool: '\eb48',
  room-service: '\eb49',
  smoke-free: '\eb4a',
  smoking-rooms: '\eb4b',
  spa: '\eb4c',
  goat: '\e901'
);
