.page-user {
  // summary table
  .view-display-id-count_cases {
    thead,
    tbody {
      tr > :nth-child(n+2) {
        text-align: right;
      }
    }

    .total {
      border-bottom: rem(1px) solid $color-border-default;
    }
  }

  // add case link
  .action-links {
    a {
      $size: 72px;

      @extend %button;
      position: absolute;
      right: $spacing-minor;
      padding: 0;
      bottom: 100%;
      z-index: 100;
      display: block;
      width: $size;
      height: $size;
      border-width: 0;
      border-radius: 50%;
      text-indent: -9999em;
      box-shadow: 0 5px 15px 0 rgba($color-black, .3);

      &::before {
        @include icon('add');
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        text-indent: 0;
        font-size: 48px;
        transform: translate(-50%, -50%);
      }
    }
  }

  // exposed filter
  .views-exposed-form {
    .form-item {
      padding-right: 0;
    }
  }

  .form-item-uid input {
    width: 290px;
  }

  .form-item-title-field-value input {
    width: 200px;
  }
}
