body.adminimal-theme table {
  th {
    background-color: $color-table-head;
    vertical-align: top;
    font-size: rem($font-size-default);
    transition: background-color $transition-default-timing;

    label {
      font-size: inherit;
    }
  }

  thead {
    th {
      font-size: $font-size-small;
    }

    tr:last-child {
      border-bottom: 1px solid $color-border-default;
    }
  }

  tbody tr {
    transition: background-color $transition-default-timing;

    &.even {
      background-color: $color-table-row-even;
    }

    &.even,
    &.odd {
      &:hover {
        background-color: $color-table-hover;
      }
    }
  }
}
