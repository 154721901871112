%button {
  background-color: $color-button-default;
  color: $color-copy-inverted;
  display: inline-block;
  padding: $spacing-small $spacing-minor;
  box-shadow: 0 3px 10px 0 rgba($color-black, .25);
  text-decoration: none;

  &:hover {
    background-color: $color-button-hover;
  }
}