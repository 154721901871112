.adminimal-theme {
  .form-checkboxes,
  .form-radios {
    display: flex;
    padding: 0 rem(10px) 1px;
    border: rem(1px) solid rgba(#000, .05);
    border-radius: rem(3px);
    flex-wrap: wrap;

    > div.form-item {
      margin-top: 0;
      margin-right: rem(20px);

      &:last-child {
        margin-right: 0;
      }
    }

    [type="radio"],
    [type="checkbox"] {
      margin-right: rem(2px);
      transform: translateY(-1px);
    }
  }
}

.form-type-checkboxes {
  // remove redundant required indicators from required checkbox-groups
  .form-item .form-required {
    display: none;
  }
}
