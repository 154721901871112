.group-vertical {
  display: flex;
  margin-top: 20px;

  [type="text"] {
    width: 100%;
  }

  .form-item {
    margin-bottom: 0;
  }

  .form-wrapper {
    margin-top: 0;
  }
}
