.adminimal-theme.node-type-case {
  .field-name-field-purpose-of-x-ray {
    font-size:  $font-size-large;
    font-weight: bold;
    margin-bottom: $spacing-minor;
    margin-top: $spacing-default;
  }

  .field-name-field-x-ray {
    margin-top: $spacing-minor;
  }

  .field-name-field-x-rays {
    display: flex;
    justify-content: space-between;

    img {
      max-width: 100%;
      height: auto;
    }

    > div {
      flex-basis: 31%;
    }
  }

  .pane-entity-view {
    margin-bottom: $spacing-outer;

    .links {
      display: block;
      margin-top: $spacing-default;
      text-align: center;

      .comment-add {
        display: none;
      }

      a {
        @extend %button;
        font-weight: bold;

        &::before {
          @extend %icon-font;
          position: relative;
          top: 1px;
          margin-right: $spacing-small;
        }
      }

      .flag-reject_case a {
        background-color: $color-red;

        &::before {
          @include icon-content('clear');
        }
      }

      .flag-accept_case a {
        background-color: $color-green;

        &::before {
          @include icon-content('check');
        }
      }
    }
  }

  .flag-message {
    position: static;
  }

  .pane-node-comment-wrapper {
    max-width: 600px;
    margin: 0 auto;

    [id^="edit-author"] {
      display: none;
    }

    .text-format-wrapper .form-type-textarea > label {
      display: none;
    }
  }
}

.adminimal-theme {
  &.page-node-edit,
  &.page-node-complete,
  &.page-node-add-case {
    #page {
      overflow: hidden;
    }
  }
}
