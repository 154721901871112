.overflow-fix-processed {
  overflow: scroll;
  min-width: 100%;

  .sticky-header {
    display: none;
  }
}

.overflow-info {
  padding-top: 10px;
  text-align: center;
  font-size: $font-size-small;
  color: $color-copy-inactive;

  &::before {
    margin-right: 10px;
    content: '\2039';
  }

  &::after {
    margin-left: 10px;
    content: '\203A';
  }
}

#field-x-rays-add-more-wrapper {
  min-width: 100%;
}
