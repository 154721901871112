.adminimal-theme .teaser-list {
  .view-filters {
    padding: $spacing-small 0;
    background-color: $color-table-head;
  }

  .views-exposed-widgets {
    margin: 0;
  }
}
