//.group-complaints-since-group {
.group-combine-fields {
  position: relative;
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;

  h3 {
    margin-bottom: 8px;
    width: 100%;
  }

  label {
    display: none;
  }

  .form-wrapper {
    margin-top: 0;
    margin-bottom: 6px;
  }

  .dependee-hidden {
    opacity: .5;
  }
}
