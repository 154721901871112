.case-assignement-list {
  li {
    display: inline-block;

    &::after {
      content: ',';
      margin-right: .25em;
    }

    &:last-child::after {
      display: none;
    }
  }
}
