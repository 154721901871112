.adminimal-theme {
  .form-textarea,
  .form-select,
  .form-text {
    &[disabled] {
      background-color: $color-field-disabled;
      cursor: not-allowed;
      color: $color-copy-inactive;
    }
  }
}
