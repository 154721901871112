.adminimal-theme a.tabledrag-handle {
  display: flex;
  margin: 0;
  padding: 0 rem(5px);
  height: rem(30px);
  align-items: center;

  .handle {
    $size: 18px;

    margin: 0;
    padding: 0;
    width: $size;
    height: $size;
    background-position: top left;
  }
}
