// Primary color palette (CI/VI/…)
$color-deep-koamaru: #12237f;
$color-aqua-deep: #015440;
$color-red-ribbon: #e20a21;
$color-endeavour: #0065ad;
$color-lochmara: #0074BD;

// Secondary color palette (UI)
$color-red: #ff1600;
$color-orange: #ff8700;
$color-yellow: #fff000;
$color-green: #00cc19;

// Gray palette
$color-black: #000;
$color-mine-shaft: #333;
$color-dusty-gray: #999;
$color-alto: #dedede;
$color-concrete: #f3f3f3;
$color-white: #fff;

// use-cases
$color-body: $color-mine-shaft;
$color-content-bg: $color-white;
$color-main: $color-deep-koamaru;
$color-border-default: $color-alto;
$color-highlight: $color-endeavour;

$color-copy: $color-black;
$color-copy-inverted: $color-white;
$color-copy-inactive: $color-dusty-gray;

$color-button-default: $color-endeavour;
$color-button-hover: $color-lochmara;

$color-field-disabled: $color-concrete;
