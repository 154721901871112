%no-drag-table {
  .sticky-header,
  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  td {
    vertical-align: top;
  }

  // drag-handle
  .tabledrag,
  .field-multiple-drag {
    padding: 0;
    width: 0;

    a {
      display: none;
    }
  }
}
