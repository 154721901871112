.page-admin-structure {
  &.adminimal-theme {
    #branding,
    #navigation {
      max-width: none;
    }

    #page {
      max-width: 100%;
      overflow-x: scroll;
    }
  }
}
