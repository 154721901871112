.date-no-float {
  .form-type-textfield label {
    display: none;
  }
}

.field-widget-date-popup {
  .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .date-float {
    width: 100%;
  }

  .date-combo .container-inline-date {
    width: auto;

    > .form-item {
      margin-bottom: 0;
    }

    label {
      float: left;
      padding-top: rem(12px);
    }
  }
}
