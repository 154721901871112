.intensity-color {
  $colors: (
    0: $color-green,
    1: $color-yellow,
    2: $color-orange,
    3: $color-red
  );

  @each $value, $color in $colors {
    &[data-intensity="#{$value}"] {
      background-color: $color;
    }
  }
}
