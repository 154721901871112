.adminimal-theme {
  #branding,
  #navigation,
  #page {
    margin: 0 auto;
    max-width: $site-max-width;
  }
  #branding {
    background-color: transparent;
  }

  #page {
    padding: $spacing-default;
  }
}
