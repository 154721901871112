.flipped-table {
  .views-table {
    tr > :first-child {
      border-right: rem(1px) solid $color-border-default;
    }

    thead {
      border-bottom: rem(1px) solid $color-border-default;

      th:nth-child(n+2) {
        background-color: $color-table-row-even;
      }
    }

    tbody {
      tr:hover th {
        background-color: transparent;
      }
    }
  }
}
