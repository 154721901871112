.field-name-field-session-start {
  .date-float {
    @extend %visually-hidden;
  }

  fieldset.date-combo {
    padding: 0;
    border-width: 0;

    legend {
      padding: 0 0 3px 3px;
    }

    .form-item {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .container-inline-date {
      .date-padding {
        padding: 0;
      }

      label {
        margin-right: 7px;
        padding-top: 6px;
      }
    }
  }
}
