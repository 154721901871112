.field-group-htabs {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .horizontal-tabs-panes {
    padding-bottom: $spacing-default;

    > fieldset {
      margin-top: 0;
    }
  }
}
