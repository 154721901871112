.adminimal-theme {
  .field-name-field-additional-x-rays,
  .field-name-field-x-rays {
    @extend %no-drag-table;

    // date-field
    .date-combo {
      margin: 0;
      padding: 0;
      border-width: 0;

      legend {
        display: none;
      }

      .date-padding {
        padding: 0;
      }

      .description {
        margin-bottom: 0;
        font-size: $font-size-small;
      }

      .form-item input {
        margin-right: 0;
        padding-right: 8px;
        width: 105px;
      }
    }

    // doc
    .field-threatened-doctor {
      input {
        width: 180px;
      }
    }

    .form-submit {
      margin-right: 0;
    }

    .remove-button {
      padding-left: 0;
    }
  }
}
