.views-exposed-widgets {
  display: flex;
  align-items: flex-start;

  .views-exposed-widget {
    padding-top: 0;

    label {
      font-weight: normal;
      font-size: rem($font-size-small);
      padding: 0;
    }

    input {
      max-width: 100%;
      width: 100%;
    }

    .views-exposed-form & .form-submit {
      // selector overload needed to overwrite base theme style
      margin: rem(18px) 0 0;
    }
  }
}
