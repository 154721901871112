.adminimal-theme ul.case-short-teaser-list {
  a {
    display: inline-block;
  }

  .views-field-changed {
    font-size: $font-size-small;
  }

  em {
    font-style: normal;
  }
}
