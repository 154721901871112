.adminimal-theme .form-wrapper {
  // required wrappers
  &.required-fields {
    // remove redundant required indicators from required radio--groups
    .form-type-radios {
      .form-item .form-required {
        display: none;
      }
    }
  }

  margin-bottom: 0;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .form-item {
    margin-bottom: 0;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}
