.adminimal-theme .profile-student {
  &-row-2,
  &-row-3 {
    display: flex;

    > div {
      margin-left: $spacing-outer;
    }

    > :first-child {
      margin-left: 0;
    }
  }

  &-row-2 {
    > div {
      flex-basis: 20%;
    }

    > :first-child {
      flex-grow: 1;
    }
  }

  &-row-3 {
    flex-wrap: wrap;

    h2 {
      padding-top: 0;
    }

    > div {
      margin-left: $spacing-outer;
      flex-basis: calc(33% - #{2 * $spacing-outer / 3});
      flex-shrink: 1;

      &:nth-child(n+2) {
        padding-top: 30px;
      }
    }

    .view-footer {
      margin-top: $spacing-default;
    }

    .user-all-cases {
      margin-left: 0;
      padding-top: 20px;
      text-align: center;
      flex-basis: 100%;
      flex-shrink: 0;

      .pane-content {
        position: relative;
        z-index: 1;

        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          z-index: -1;
          display: block;
          height: 1px;
          background-color: $color-border-default;
          content: '';
        }
      }

      a {
        @extend %button;
        position: relative;

        &::after {
          position: absolute;
          right: 0 - $spacing-minor;
          left: 0 - $spacing-minor;
          z-index: -1;
          background: $color-content-bg;
          content: '';
          height: 100%;
          pointer-events: none;
        }
      }

      + div {
        margin-left: 0;
      }
    }

    .views-field-field-patient-nr,
    .views-field-field-shortcode {
      display: inline-flex;
    }

    .views-field-field-patient-nr::before {
      content: '#';
    }

    .views-field-field-patient-nr + .views-field-field-shortcode::before {
      margin: 0 7px 0 4px;
      content: '\2022';
    }
  }
}
