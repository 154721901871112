.adminimal-theme {
  h2 {
    padding-top: $spacing-outer;
    margin-bottom: $spacing-default;
    font-size: rem($font-size-x-large);
  }

  h3 {
    font-size: rem($font-size-large);
  }
}
