@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

$spacing-outer: 40px;
$spacing-default: 20px;
$spacing-minor: 10px;
$spacing-small: 5px;

$site-max-width: 1200px;
